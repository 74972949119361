<template>
  <laptop v-if="$r.breakpoint.lgAndUp"></laptop>
  <mobile v-else></mobile>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "user",
  components: {
    laptop: defineAsyncComponent(() => import("./laptop")),
    mobile: defineAsyncComponent(() => import("./mobile")),
  },
};
</script>
<style>
.template-user {
  position: relative;
  z-index: 1;
}
</style>
