<template>
  <r-container class="order-cart" full-width>
    <template v-if="size > 0">
      <div
        class="d-flex background-one br-lg pa-5"
        v-for="(item, id) in cart"
        :key="id"
      >
        <div class="w-30">
          <r-img
            :src="'/' + item.images[0]"
            :alt="item.title"
            auto-size
          ></r-img>
        </div>
        <div class="w-70 ps-2">
          <div class="d-flex h-space-between">
            <span class="display-5">{{ item.title }}</span>
            <r-btn
              icon
              class="color-error-text br-lg elevation-none"
              @click.prevent="del(id)"
            >
              <r-icon v-html="$r.icons.delete"></r-icon>
            </r-btn>
          </div>
          <div class="mt-10" v-if="$r.breakpoint.mdAndUp">
            <div class="title font-weight-bold">
              {{ $t("special_product") }}
            </div>
            <div class="d-flex flex-wrap">
              <div class="cart-special v-center me-2 mt-2">
                <div class="label-1 font-weight-bold">{{ $t("weight") }}:</div>
                <div class="label-2 color-one-text mt-2">
                  {{ $t(["weight_unit", [item.weight]]) }}
                </div>
              </div>
              <template v-if="item.special">
                <div
                  v-for="(item, ii) in item.special.slice(0, 3)"
                  :key="ii"
                  class="cart-special v-center me-2 mt-2"
                >
                  <div class="label-1 font-weight-bold">{{ item["k"] }}:</div>
                  <div class="label-2 color-one-text mt-2">
                    {{ item["v"] }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <r-row class="v-end mt-2 mt-md-10">
            <r-col class="col-12 md-6"
              ><div class="d-flex v-center">
                <img
                  src="/storage/site/icons/shield-tick.png"
                  srcset="
                    /storage/site/icons/shield-tick@2x.png 2x,
                    /storage/site/icons/shield-tick@3x.png 3x
                  "
                  alt="shield-tick"
                  class="me-2"
                />
              <span class="label-1">{{ $t("guarantee_product") }}</span>
              </div></r-col
            >
            <r-col class="col-6 md-3">
              <r-number-input
                class="mt-n3"
                btnText
                v-model="$r.store.cart[id].count_cart"
                tile
                :max="item.count"
                :min="1"
              ></r-number-input>
            </r-col>
            <r-col class="col-6 md-3">
              <span
                v-if="item.price_b"
                class="price-disabled color-disabled-text"
                >{{ $n(item.price_b * item.count_cart) }}</span
              >
              <br />
              <span class="text-no-wrap color-warning-text">
                {{ $n(item.price * item.count_cart) }}
                {{ $t($r.store.unit_price) }}
              </span>
            </r-col>
          </r-row>
        </div>
      </div>
    </template>
    <r-row v-else>
      <r-col class="col-12 text-center display-4">
        {{ $t("cart_empty") }}
      </r-col>
    </r-row>
    <r-row v-if="showAction" class="h-end">
      <r-col class="col-auto">
        <r-btn outlined class="color-warning-text" @click.prevent="go()">
          {{ $t("see_cart") }}
        </r-btn>
      </r-col>
      <r-col class="col-auto">
        <r-btn
          outlined
          class="color-info-text"
          @click.prevent="$r.store['showCart'] = false"
        >
          {{ $t("continue_shopping") }}
        </r-btn>
      </r-col>
    </r-row>
  </r-container>
</template>

<script>
export default {
  name: "orderCard",
  props: {
    showAction: Boolean,
  },
  computed: {
    size() {
      return this.$helper.size(this.cart);
    },
    cart() {
      this.$storage.set("carts", this.$r.store.cart);
      return this.$r.store.cart;
    },
  },
  methods: {
    del(id) {
      delete this.$r.store.cart[id];
      this.$storage.set("carts", this.$r.store.cart);
    },
    go() {
      this.$r.store["showCart"] = false;
      this.$router.push({ name: "carts" });
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";

.order-cart {
  .price-disabled {
    text-decoration: line-through;
  }

  .cart-special {
    display: flex;
    padding: 4px;
    border-bottom: 1px solid var(--color-border);
  }
}
</style>
