import {createRouter, createWebHistory} from "vue-router";
import {auth, guest} from "./guards";

import IndexLayout from "../layouts/home/index";
import UserLayout from "../layouts/user/index";

const law = () => import("../views/index/user/law");
const base = () => import("../views/index/home");
const notFound = () => import("../views/index/notFound");

/* user panel*/
const wish_list = () => import("../views/index/user/wish_list");
const dashboard = () => import("../views/index/user/index.vue");
const profile = () => import("../views/index/user/profile");
const info = () => import("../views/index/user/info");
const links = () => import("../views/index/user/links");
const full_step = () => import("../views/index/user/fullStep");
/* {{place new import}} */
const faqs = () => import("app/extension/home/faq/view/faq.vue");
const ticket = () => import("app/extension/home/ticket/view/index_user.vue");
const pages = () => import("app/extension/home/page/view/page.vue");
const singleBlog = () => import("app/extension/home/blog/view/singleBlog.vue");
const blogs = () => import("app/extension/home/blog/view/blogs.vue");
const userSetting = () => import("app/extension/renus/user/view/setting.vue");
const login = () => import("app/extension/renus/user/view/login.vue");

const products = () => import("../views/index/products");
const singleProduct = () => import("../views/index/singleProduct");
const products_e = () => import("../views/index/products_e");
const singleProduct_e = () => import("../views/index/singleProduct_e");
const carts = () => import("../views/index/carts");
const carts_e = () => import("../views/index/carts_e");
const paymentVerify = () => import("../views/index/paymentVerify");
const referral = () => import("../views/index/referral");
const event_managements = () => import("../views/index/eventManagements.vue");

const routes = [
  {
    path: "/",
    component: IndexLayout,
    children: [
      { path: "", name: "base", component: base },
      {
        path: "/user",
        component: UserLayout,
        beforeEnter: (to, from, next) => {
          auth(to, from, next);
        },
        children: [
          {path: "", name: "dashboard", component: dashboard},
          /* {{place new Route user}} */
          { path: "/user/tickets", name: "tickets", component: ticket },
          {
            path: "user-settings",
            name: "userSettings",
            component: userSetting,
          },
          { path: "profile", name: "profile", component: profile },
          { path: "wish-list", name: "wish_list", component: wish_list },
          { path: "info", name: "info", component: info },
          { path: "full-step", name: "full_step", component: full_step },
          { path: "links", name: "links", component: links },
        ],
      },
      {
        path: "/home/laws",
        name: "laws",
        component: law,
      },
      /* {{place new Route home}} */
      {
        path: "/home/pages/:slag",
        name: "pages",
        component: pages,
        props: true,
      },
      {
        path: "/home/singleBlog/:blog_id/:title",
        name: "singleBlog",
        component: singleBlog,
        props: true,
      },
      {
        path: "/home/blogs/:genum?/:tag?",
        name: "blogs",
        component: blogs,
        props: true,
      },
      {path: "home/login/:token?", name: "login", component: login},
      {
        path: "/home/pages/:slag",
        name: "pages",
        component: pages,
        props: true,
      },
      {
        path: "/r/:gen",
        name: "referral",
        component: referral,
        props: true,
      },
      {
        path: "/home/singleBlog/:blog_id/:title",
        name: "singleBlog",
        component: singleBlog,
        props: true,
      },
      {
        path: "/home/blogs/:genum?/:tag?",
        name: "blogs",
        component: blogs,
        props: true,
      },
      {
        path: "/home/faq",
        name: "faqs",
        component: faqs,
      },
      {
        path: "home/login",
        name: "login",
        component: login,
        beforeEnter: (to, from, next) => {
          guest(to, from, next);
        },
      },
      {
        path: "/home/products/:gen?/:category?",
        name: "products",
        component: products,
        props: true,
      },
      {
        path: "/home/products/show/:product_id/:product_name",
        name: "singleProduct",
        component: singleProduct,
        props: true,
      },
      {
        path: "/home/events",
        name: "products_e",
        component: products_e,
      },
      {
        path: "/home/events/show/:product_id/:product_name",
        name: "singleProduct_e",
        component: singleProduct_e,
        props: true,
      },
      {
        path: "/home/event-managements/:product_id/:product_name",
        name: "event_managements",
        component: event_managements,
        props: true,
      },
      {
        path: "/home/carts",
        name: "carts",
        component: carts,
      },
      {
        path: "/home/carts_e",
        name: "carts_e",
        component: carts_e,
      },
      {
        path: "/home/payment/:status/:refId",
        name: "paymentVerify",
        component: paymentVerify,
        props: true,
      },
      { path: "/:pathMatch(.*)*", name: "not_found", component: notFound },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === "production"
      ? process.env.API_production_URL
      : process.env.API_URL
  ),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        setTimeout(() => {
          resolve({ left: 0, top: savedPosition["top"] });
        }, 500);
      } else {
        resolve({ left: 0, top: 0 });
      }
    });
  },
});

export default router;
